import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Upload, message } from 'antd';
import { useCallback, useState } from 'react';
import './styles.css';
import { useAppDispatch } from 'app/store';
import { createStaticSegments } from 'app/store/slices/dataset/thunks';

export const FileUploadModalForm = () => {
	const dispatch = useAppDispatch();

	const [stateIsOpen, setStateIsOpen] = useState<boolean>(false);
	const [form] = Form.useForm();
	const [fileList, setFileList] = useState<any[]>([]);

	const callbacks = {
		handleOpen: useCallback(() => {
			setStateIsOpen(true);
		}, []),
		handleClose: useCallback(() => {
			setStateIsOpen(false);
		}, []),
		onFileChange: useCallback(({ fileList }: any) => {
			setFileList(fileList);
		}, []),
		handleSubmit: useCallback(
			(values: any) => {
				const formData = new FormData();
				formData.append('name', values.name);
				formData.append('file', fileList[0].originFileObj);

				dispatch(createStaticSegments({ formData }))
					.unwrap()
					.catch((e: any) => {
						e.message.includes('TABLE_ALREADY_EXISTS')
							? message.error(`Сегмент с заданным именем уже существует`)
							: message.error(`${e}`);
					});
				setFileList([]);
				callbacks.handleClose();
			},
			[fileList],
		),
	};
	//
	return (
		<>
			<Button onClick={callbacks.handleOpen} type='primary' className='action'>
				Добавить Static сегмент
			</Button>
			<Modal
				title='Добавить Static сегмент'
				open={stateIsOpen}
				footer={null}
				onCancel={callbacks.handleClose}
				destroyOnClose
			>
				<Form
					form={form}
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					initialValues={{ remember: false }}
					preserve={false}
					onFinish={callbacks.handleSubmit}
				>
					<Form.Item
						label='Название сегмента'
						name='name'
						rules={[{ required: true, message: 'Пожалуйста, введите значение' }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name='file'
						label='Файл'
						preserve={false}
						rules={[{ required: true, message: 'Пожалуйста, загрузите файл' }]}
					>
						<Upload
							fileList={fileList}
							maxCount={1}
							onChange={callbacks.onFileChange}
							beforeUpload={() => false}
						>
							<Button icon={<UploadOutlined />}>Выберите файл</Button>
						</Upload>
					</Form.Item>

					<div className='button-wrapper'>
						<Button type='primary' htmlType='submit'>
							Загрузить
						</Button>
					</div>
				</Form>
			</Modal>
		</>
	);
};
