export interface IResponseTypeSqlQuery {
	data: IDataSqlQuery;
	database_id: number;
	started_at: string;
	error?: any;
}
export interface IResponseTypeField {
	id: number;
	name: string;
}

export interface IDataSqlQuery {
	rows: IRow[];
	cols: ICol[];
}

export type IRow = (string | number | boolean | bigint)[];
export interface ICol {
	display_name: string;
	source: string;
	name: string;
	field_ref: any[];
	base_type: string;
	effective_type: string;
}

export interface IRequestSaveValues {
	name: string;
	description?: string;
	display?: string;
	collectionId: string | null;
	id: number;
}

export enum FunnelGranularityTypes {
	Days = 'days',
	Hours = 'hours',
	Minutes = 'minutes',
	Seconds = 'seconds',
}
