import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as thunks from './thunks';
import { IResponseTypeSqlQuery } from './type';
interface ISqlQuerySliceState {
	isLoading: boolean;
	sqlQuery: string;
	data: IResponseTypeSqlQuery | null;
	questionId: number | null;
	visualizationStateOpenId: string | null;
}

const initialState: ISqlQuerySliceState = {
	isLoading: false,
	sqlQuery: '',
	data: null,
	questionId: null,
	visualizationStateOpenId: null,
};

export const sqlQuerySlice = createSlice({
	name: 'sqlQuerySlice',
	initialState: initialState,
	reducers: {
		setIsLoading: (state, action: PayloadAction<boolean>): ISqlQuerySliceState => ({
			...state,
			isLoading: action.payload,
		}),
		setSqlQuery: (state, action: PayloadAction<string>): ISqlQuerySliceState => ({
			...state,
			sqlQuery: action.payload,
		}),
		setData: (state, action: PayloadAction<IResponseTypeSqlQuery>): ISqlQuerySliceState => ({
			...state,
			data: action.payload,
		}),
		setQuestionId: (state, action: PayloadAction<number>): ISqlQuerySliceState => ({
			...state,
			questionId: action.payload,
		}),
		setVisualizationStateOpenId: (
			state,
			action: PayloadAction<string | null>,
		): ISqlQuerySliceState => ({
			...state,
			visualizationStateOpenId: action.payload,
		}),
		reset: (): ISqlQuerySliceState => initialState,
	},
});

export const actions = sqlQuerySlice.actions;
export const sqlQueryReducer = sqlQuerySlice.reducer;
export { thunks };
