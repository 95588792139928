import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { actions } from './dataset';
import { actions as settingActions } from 'app/store/slices/settings';
import * as api from './api';
import { IResponseTypeSqlQuery } from 'app/store/slices/sql_query/type';
import { IDatabaseSetting } from '../settings/type';
import { RootState } from 'app/store/store';
import { getTables } from '../database/thunks';
import {
	FormDataPayload,
	IPossibleValuesParams,
	IRequestDataset,
	IResponseSegmentData,
} from './type';
import {
	getQueryForEventPropertiesPossibleValuesDataset,
	getQueryForFilterPossibleValuesDataset,
} from './utils';
import { PossibleValuesTableTypes } from 'app/pages/HomePage/components/FilterDrawer/types';

export const initDataset = createAsyncThunk<void>(`api/post/dataset`, async (_, { dispatch }) => {
	const errors: Error[] = [];
	try {
		dispatch(actions.setIsLoading(true));
		await dispatch(getDataset())
			.unwrap()
			.catch(error => {
				errors.push(error);
			});
		await dispatch(getColumnPossibleValuesCatalog())
			.unwrap()
			.catch(error => {
				errors.push(error);
			});
		await dispatch(getEventsPropertiesPossibleValuesCatalog())
			.unwrap()
			.catch(error => {
				errors.push(error);
			});
		if (errors.length) {
			message.error(
				`Отсутствуют данные о пути получения ${errors.reduce((accumulator, error) => {
					return accumulator + error.message + ', ';
				}, '')} пожалуйста, проверьте настройки`,
				6,
			);
		}
	} catch (e: any) {
		message.error(`${e.message}`, 6);
	} finally {
		dispatch(actions.setIsLoading(false));
	}
});

export const getDataset = createAsyncThunk<void>(`api/post/dataset`, async (_, { dispatch }) => {
	const values = JSON.parse(localStorage.getItem('settingDatabase') || 'null') as IDatabaseSetting;
	if (values?.db && values?.tableEvents) {
		dispatch(settingActions.initDatabaseSetting(values));
		const data: IResponseTypeSqlQuery = await api.getDataset({
			database: Number(values.db),
			query: {
				'source-table': Number(values.tableEvents),
			},
			type: 'query',
		});
		dispatch(actions.setData(data));
	} else {
		throw 'событий и параметров';
	}
});

export const getPossibleValues = createAsyncThunk<IResponseTypeSqlQuery, IPossibleValuesParams>(
	`api/post/dataset`,
	async (params, { dispatch, getState }) => {
		await dispatch(getTables());
		const state = getState() as RootState;
		const tables = state.database.tables;
		let query: IRequestDataset;
		switch (params.tableType) {
			case PossibleValuesTableTypes.EventProperty:
				query = getQueryForEventPropertiesPossibleValuesDataset(params, tables) as IRequestDataset;
				break;
			default:
				query = getQueryForFilterPossibleValuesDataset(params, tables) as IRequestDataset;
				break;
		}
		const data: IResponseTypeSqlQuery = await api.getDataset(query);
		return data;
	},
);

export const getColumnPossibleValuesCatalog = createAsyncThunk<void>(
	`api/post/dataset`,
	async (_, { dispatch, getState }) => {
		await dispatch(getTables());
		const state = getState() as RootState;
		const tables = state.database.tables;
		const values = JSON.parse(localStorage.getItem('settingDatabase') || 'null') as IDatabaseSetting;
		if (values?.db && values?.tableColumnPossibleValues) {
			dispatch(settingActions.initDatabaseSetting(values));
			const tableId = Number(values.tableColumnPossibleValues);
			const fieldId = tables
				.find(table => table.id === tableId)
				?.fields?.fields.find(field => field.name === 'column')?.id;
			const data: IResponseTypeSqlQuery = await api.getDataset({
				database: Number(values.db),
				query: {
					'source-table': tableId,
					aggregation: [['distinct', ['field', fieldId, null]]],
					breakout: [['field', fieldId, null]],
				},
				type: 'query',
			});
			dispatch(actions.setColumnPossibleValues(data));
		} else {
			throw 'возможных значений фильтров';
		}
	},
);

export const getEventsPropertiesPossibleValuesCatalog = createAsyncThunk<void>(
	`api/post/dataset`,
	async (_, { dispatch }) => {
		const values = JSON.parse(localStorage.getItem('settingDatabase') || 'null') as IDatabaseSetting;
		if (values?.db && values?.tableEventPropertiesPossibleValues) {
			dispatch(settingActions.initDatabaseSetting(values));
			const data: IResponseTypeSqlQuery = await api.getDataset({
				database: Number(values.db),
				query: {
					'source-table': Number(values.tableEventPropertiesPossibleValues),
				},
				type: 'query',
			});
			dispatch(actions.setEventsPropertiesPossibleValues(data));
		} else {
			throw 'возможных значений свойств событий';
		}
	},
);

export const getFilters = createAsyncThunk<void>(`api/post/dataset`, async (_, { dispatch }) => {
	try {
		const values = JSON.parse(localStorage.getItem('settingDatabase') || 'null') as IDatabaseSetting;
		const fEventTableId = JSON.parse(localStorage.getItem('filterTableId') || 'null');
		if (values?.db && fEventTableId) {
			const data: IResponseTypeSqlQuery = await api.getDataset({
				database: Number(values.db),
				query: {
					'source-table': Number(fEventTableId),
				},
				type: 'query',
			});
			dispatch(actions.setFilters(data));
		} else {
			throw 'Отсутствуют данные о полях фильтров, пожалуйста, проверьте наличие таблицы events_map!';
		}
	} catch (e: any) {
		message.error(`${e}`, 6);
	}
});
export const changeDataset = createAsyncThunk<void, IDatabaseSetting>(
	`api/post/dataset`,
	async (databaseSetting, { dispatch }) => {
		try {
			if (
				databaseSetting.db &&
				databaseSetting.tableEvents &&
				databaseSetting.tableColumnPossibleValues &&
				databaseSetting.tableEventPropertiesPossibleValues
			) {
				const data: IResponseTypeSqlQuery = await api.getDataset({
					database: Number(databaseSetting.db),
					query: {
						'source-table': Number(databaseSetting.tableEvents),
					},
					type: 'query',
				});
				dispatch(actions.setData(data));
			} else {
				throw 'Отсутствуют данные о пути получения событий и параметров, пожалуйста, заполните настройки';
			}
		} catch (e: any) {
			message.error(`${e}`, 6);
		}
	},
);

export const getSegments = createAsyncThunk<void>(`api/get/segment`, async (_, { dispatch }) => {
	try {
		const data: IResponseSegmentData[] = await api.getSegments();
		dispatch(actions.setSegmentsData(data));
	} catch (e: any) {
		message.error(`${e}`);
	}
});

export const getStaticSegments = createAsyncThunk<void>(
	`api/get/static_segment`,
	async (_, { dispatch }) => {
		try {
			const data: IResponseSegmentData[] = await api.getStaticSegments();
			dispatch(actions.setStaticSegmentsData(data));
		} catch (e: any) {
			message.error(`${e}`);
		}
	},
);

export const createStaticSegments = createAsyncThunk<void, FormDataPayload>(
	`api/post/static_segment`,
	async ({ formData }, { dispatch }) => {
		try {
			const response = await api.createStaticSegment(formData);
			message.success(`Сегмент ${response.name} создан успешно.`);
			dispatch(getStaticSegments());
		} catch (e: any) {
			if (e.response) {
				throw e.response.data.detail;
			} else {
				message.error(`${e}`);
			}
		}
	},
);
