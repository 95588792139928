import { IResponseTypeSqlQuery } from 'app/store/slices/sql_query/type';

export type FilterType = {
	id: string;
	type: FilterTypes;
	name: string;
	operator: FilterOperators;
	value: string | string[];
	granularity?: string;
	possibleValues?: IResponseTypeSqlQuery | null;
};

export enum FilterTypes {
	DateTime = 'type/DateTime',
	Date = 'type/Date',
	Text = 'type/Text',
	Boolean = 'type/Boolean',
	Integer = 'type/Integer',
	BigInteger = 'type/BigInteger',
	Float = 'type/Float',
}

export enum PossibleValuesTableTypes {
	Filter,
	EventProperty,
}

export enum FilterOperators {
	Is = '=',
	IsNot = '!=',
	Contains = 'ILIKE',
	NotContains = 'NOT ILIKE',
	Less = '<',
	LessOrEqual = '<=',
	Greater = '>',
	GreaterOrEqual = '>=',
}
