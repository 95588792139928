import { PossibleValuesTableTypes } from 'app/pages/HomePage/components/FilterDrawer/types';

export interface IRequestDataset {
	database: number;
	query: {
		'source-table': number;
		limit?: number;
		filter?: any;
		aggregation?: any;
		breakout?: any;
	};
	type: 'query';
}

export interface IPossibleValuesParams {
	id?: string;
	dbId: number;
	tableId: number;
	tableType: PossibleValuesTableTypes;
	fieldName: string;
	newValue: string;
	eventType?: string | null;
}

export interface IResponseSegmentData {
	description: string | null;
	name: string;
    definition: Definition | null;
	field: string | null;
	id: number;
	type: SegmentType | null;
}

type Definition = {
    "source-table": number;
    aggregation: [string[]];
    filter: any;
};

export enum SegmentType {
	Static = 'static',
	Dynamic = 'dynamic',
}

export interface FormDataPayload {
	formData: FormData;
}
