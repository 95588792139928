import { useAppSelector } from 'app/store';
import { Table } from 'antd';
import './styles.css';
import { FileUploadModalForm } from 'app/pages/HomePage/components/FileUploadModalForm';

export const SegmentsList = () => {
	const dynamicSegments = useAppSelector(state => state.dataset.segmentsData);
	const staticSegments = useAppSelector(state => state.dataset.staticSegmentsData);
	const redirectBaseUrl = process.env.REACT_APP_REDIRECT_URL || 'https://metabase.perek.epoch8.co';
	const columnsDynamic = [
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name',
			render: (name: any) => {
				const segmentId = dynamicSegments.find(item => item.name === name)?.id;
				return <a href={`${redirectBaseUrl}/admin/datamodel/segment/${segmentId}`} target="_blank" rel="noopener noreferrer">{name}</a>;
			},
		},
		{
			title: 'Описание',
			dataIndex: 'description',
			key: 'description',
		},
	];
	const columnsStatic = [
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Описание',
			dataIndex: 'description',
			key: 'description',
		},
	];

	return (
		<div>
			<div className='segments-static'>
				<h1>Сегменты Static (Clickhouse)</h1>
				<FileUploadModalForm />
			</div>
			<Table dataSource={staticSegments} columns={columnsStatic} />
			<h1>Сегменты Dynamic (Metabase)</h1>
			<Table dataSource={dynamicSegments} columns={columnsDynamic} />
		</div>
	);
};
