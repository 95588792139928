import { useCallback, useState } from 'react';
import { Button, Drawer, Select, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { getOptionsFromSegmentsSource } from 'app/utils/fieldUtils';
import { useAppSelector } from 'app/store';
import './styles.css';
import { IResponseSegmentData, SegmentType } from 'app/store/slices/dataset/type';
import { getFieldById } from 'app/store/slices/sql_query/api';

type Props = {
	onSubmit: (value: IResponseSegmentData[]) => void;
};

const segmentsSourceOptions = [
	{ label: 'Dynamic (Metabase)', value: SegmentType.Dynamic },
	{ label: 'Static (Clickhouse)', value: SegmentType.Static },
];

export const SegmentsDrawer = ({ onSubmit }: Props) => {
	const [stateIsOpen, setStateIsOpen] = useState<boolean>(false);
	const [stateSegments, setStateSegments] = useState<IResponseSegmentData[]>([]);
	const dynamicSegments = useAppSelector(state => state.dataset.segmentsData);
	const staticSegments = useAppSelector(state => state.dataset.staticSegmentsData);

	const callbacks = {
		handleOpen: useCallback(() => {
			setStateIsOpen(true);
		}, []),
		handleClose: useCallback(() => {
			setStateIsOpen(false);
		}, []),
		handleCreateSegmentWhere: useCallback(() => {
			setStateSegments([
				...stateSegments,
				{
					id: stateSegments.length + 1,
					definition: null,
					name: '',
					field: null,
					description: '',
					type: SegmentType.Dynamic,
				},
			]);
		}, [stateSegments]),
		handleRemoveSegmentWhere: useCallback(
			(id: number) => {
				setStateSegments(stateSegments.filter(item => item.id !== id));
			},
			[stateSegments],
		),
		handleSourceChange: useCallback(
			(value: string, id: number) => {
				const index = stateSegments.findIndex(item => item.id === id);
				if (index + 1) {
					const newSegments = [...stateSegments];
					newSegments[index] = { ...stateSegments[index], type: value as SegmentType };
					setStateSegments(newSegments);
				}
			},
			[stateSegments],
		),
		handleValueChange: useCallback(
			async (value: string, id: number) => {
				const index = stateSegments.findIndex(item => item.id === id);
				if (index + 1) {
					const newSegments = [...stateSegments];
					const currentSegment = stateSegments[index]
					let definition = null
					let filterFieldName = null
					if (currentSegment.type === SegmentType.Dynamic) {
						definition = dynamicSegments.find(item => item.name === value)?.definition || null
						const filterFieldId = definition?.filter[1][1] as number;
						const filterField = await getFieldById(filterFieldId);
						filterFieldName = filterField.name
					}
					newSegments[index] = { ...stateSegments[index], name: value, definition: definition, field: filterFieldName};
					setStateSegments(newSegments);
				}
			},
			[stateSegments],
		),
		handleSubmit: useCallback(() => {
			onSubmit(stateSegments);
			setStateIsOpen(false);
		}, [stateSegments, onSubmit]),
	};
	return (
		<>
			<Button onClick={callbacks.handleOpen} className='add-button'>
				Выбрать сегмент
			</Button>
			<Drawer
				open={stateIsOpen}
				title='Сегменты'
				onClose={callbacks.handleClose}
				width={700}
				extra={
					<Space>
						<Button type='primary' onClick={callbacks.handleSubmit}>
							Сохранить
						</Button>
					</Space>
				}
			>
				<div className='container__select-field-param'>
					{stateSegments.map(item => {
						return (
							<div className='field-container' key={item.id}>
								<Select
									className='select-segments-field-param'
									placeholder='Выберите источник'
									onChange={value => callbacks.handleSourceChange(value, item.id)}
									options={segmentsSourceOptions}
								/>
								<Select
									className='select-segments-field-param'
									placeholder='Выберите сегмент'
									onChange={value => callbacks.handleValueChange(value, item.id)}
									options={getOptionsFromSegmentsSource(item.type, staticSegments, dynamicSegments) || []}
								/>
								<Button
									className='remove-action-with-field'
									title='Удалить сегмент'
									onClick={() => callbacks.handleRemoveSegmentWhere(item.id)}
									icon={<DeleteOutlined />}
								/>
							</div>
						);
					})}
				</div>
				<Button onClick={callbacks.handleCreateSegmentWhere}>Добавить</Button>
			</Drawer>
		</>
	);
};
