import axios from 'axios';
import { IResponseTypeSqlQuery } from 'app/store/slices/sql_query/type';
import { IRequestDataset } from './type';

export const getDataset = async (values: IRequestDataset): Promise<IResponseTypeSqlQuery> => {
	return axios
		.post('api/dataset', values)
		.then(function (response) {
			return response.data as IResponseTypeSqlQuery;
		})
		.catch(function (error) {
			throw error;
		});
};

import { IResponseSegmentData } from './type';

export const getSegments = async (): Promise<IResponseSegmentData[]> => {
	return axios
		.get('api/segment')
		.then(function (response) {
			return response.data as IResponseSegmentData[];
		})
		.catch(function (error) {
			throw error;
		});
};

export const getStaticSegments = async (): Promise<IResponseSegmentData[]> => {
	return axios
		.get('api/static_segments')
		.then(function (response) {
			return response.data as IResponseSegmentData[];
		})
		.catch(function (error) {
			throw error;
		});
};

export const createStaticSegment = async (formData: FormData): Promise<IResponseSegmentData> => {
	return axios
		.post('api/static_segments', formData)
		.then(function (response) {
			return response.data as IResponseSegmentData;
		})
		.catch(function (error) {
			throw error;
		});
};
