import { EventType, FunnelType } from 'app/pages/HomePage/components/EventList/types';
import {
	FilterOperators,
	FilterType,
	FilterTypes,
} from 'app/pages/HomePage/components/FilterDrawer/types';
import { FunnelGranularityTypes } from 'app/store/slices/sql_query/type';
import { format } from 'sql-formatter';

import _ from 'lodash';
import { IResponseSegmentData, SegmentType } from '../dataset/type';

export const generateSqlQuery = (
	events: EventType[],
	funnelWindow: FunnelType,
	filters: FilterType[],
	groupby: FilterType[],
	segments: IResponseSegmentData[],
	eventsOrder: string,
	toSave: boolean,
): string => {
	const filteredFilters = filters.filter(item => item.name !== '');
	const eventsWithProps = getEventTypeWithProps(events);
	const eventTypes = getEventTypes(events);
	const eventsByLevel = getEventsByLevel(events);
	const eventAlias = getEventAlias(events);
	const eventTypesString = getStringEventTypes(events);

	// проверка шага временная, необходимо убрать после утверждения структуры
	const whereStep1 = getWhereByStep(filteredFilters, segments, toSave);
	const t1 = getT1(groupby, eventsWithProps, whereStep1);
	const t2 = getT2(groupby, eventsOrder, funnelWindow, eventTypes);
	const t3 = getT3(groupby, eventsOrder);
	const t4 = getT4(groupby, eventsOrder, eventsByLevel, eventAlias, eventTypesString);
	if (!toSave) {
		return format(t1 + t2 + t3 + t4, { language: 'bigquery', keywordCase: 'upper' });
	} else {
		return t1 + t2 + t3 + t4;
	}
};

const getWhereByStep = (
	filters: FilterType[],
	segments: IResponseSegmentData[],
	toSave: boolean,
) => {
	const staticSegments = segments.filter(item => item.type === SegmentType.Static);
	const dynamicSegments = segments.filter(item => item.type === SegmentType.Dynamic);
	const dateFilters = filters.filter(item => item.type === FilterTypes.Date || item.type === FilterTypes.DateTime)
	const whereFromDateFilters = _getWhereFromDateFilters(dateFilters, toSave)
	const whereFromFilters = _getWhereFromFilters(filters);
	const whereFromStaticSegments = _getWhereFromStaticSegments(staticSegments);
	const whereFromDynamicSegments = _getWhereFromDynamicSegments(dynamicSegments);
	return (
		`${whereFromDateFilters ? whereFromDateFilters : ''}` +
		`${whereFromFilters ? 'and ( ' + whereFromFilters : ''}` +
		`${whereFromStaticSegments ? 'and ( ' + whereFromStaticSegments : ''}` +
		`${whereFromDynamicSegments ? 'and ( ' + whereFromDynamicSegments : ''}` +
		`)`
	);
};

const _getFilterExpressionFromMetabseDefinition = (segment: IResponseSegmentData) => {
	const filterFieldName = segment.field
	let operator = segment.definition?.filter[0];
	let value
	if (operator === "not-empty") {
		operator = "!=" 
		value = "null"
	}
	else {
		value = segment.definition?.filter[2];
	}
	return filterFieldName + " " + operator + " " + value;
};

const _getWhereFromDynamicSegments = (segments: IResponseSegmentData[]) => {
	return segments.reduce<string>((acc, item, index) => {
		const isLast = index === segments.length - 1;

		return (acc += `${_getFilterExpressionFromMetabseDefinition(item)} ${isLast ? ')\n' : ' and '}`);
	}, '');
};

const _getWhereFromStaticSegments = (segments: IResponseSegmentData[]) => {
	return segments.reduce<string>((acc, item, index) => {
		const isLast = index === segments.length - 1;

		return (acc += ` user_id IN (SELECT user_id FROM segments.${item.name})${
			isLast ? ')\n' : ' and'
		}`);
	}, '');
};

const _getWhereFromDateFilters = (filters: FilterType[], toSave: boolean) => {
	return filters.reduce<string>(
		(acc, item, index) => {
			const isLast = index === filters.length - 1;


			if (!toSave) {
				return (acc += ` ${item.name} between \'${item.value[0]}\' and \'${item.value[1]}\'${
					isLast ? '\n' : ' and'
				}`);
			} else {
				acc = acc.slice(0, -3);
				return (acc += ` [[and {{date}}]]${isLast ? '\n' : ' and'}`);
			}

		},
		filters.length ? 'and' : '',
	);
};


const _getWhereFromFilters = (filters: FilterType[]) => {
	return filters.reduce<string>((acc, item, index) => {
		const isLast = index === filters.length - 1;

		switch (item.type) {
			case FilterTypes.DateTime:
			case FilterTypes.Date:
				return acc
			case FilterTypes.Boolean:
				return (acc += ` ${item.name} ${item.operator} ${item.value}${isLast ? ')\n' : ' and'}`);
			case FilterTypes.Integer:
			case FilterTypes.BigInteger:
			case FilterTypes.Float:
				return (acc += ` ${item.name} ${item.operator} ${item.value}${isLast ? ')\n' : ' and'}`);
			default:
				return (acc += ` ${item.name} ${item.operator} '${
					item.operator === FilterOperators.Contains || item.operator === FilterOperators.NotContains
						? '%'
						: ''
				}${item.value}${
					item.operator === FilterOperators.Contains || item.operator === FilterOperators.NotContains
						? '%'
						: ''
				}'${isLast ? ')\n' : ' and'}`);
		}
	}, '');
};

const generatePropsByEventType = (props: FilterType[]): string => {
	const groupingByPropType = _.groupBy(props, item => item.name);
	const resultStringWithProps: string[] = [];
	Object.keys(groupingByPropType).forEach((item, index) => {
		if (index + 1 > 1) {
			resultStringWithProps.push(
				`and (${groupingByPropType[item]
					.map(el => `${item} ${el.operator} '${el.value}'`)
					.join(' OR ')})`,
			);
		} else {
			resultStringWithProps.push(
				`(${groupingByPropType[item].map(el => `${item} ${el.operator} '${el.value}'`).join(' OR ')})`,
			);
		}
	});

	return resultStringWithProps.join(' ');
};

const getEventTypeWithProps = (events: EventType[]): string => {
	return events.reduce<string>((acc, item, index) => {
		const isLast = index === events.length - 1;
		if (item.filters.length) {
			return (acc += `(event_type = '${item.value}' and ${generatePropsByEventType(item.filters)})\n ${
				isLast ? '' : 'or '
			}`);
		}
		return (acc += `event_type = '${item.value}' \n ${isLast ? '' : 'or '}`);
	}, '');
};

const getEventTypes = (events: EventType[]): string => {
	return events.reduce<string>((acc, item, index) => {
		const isLast = index === events.length - 1;
		return (acc += `event_type = '${item.value}'${isLast ? '\n' : ',\n'}`);
	}, '');
};

const getEventsByLevel = (events: EventType[]): string => {
	return events.reduce<string>((acc, _, index) => {
		const isLast = index === events.length - 1;
		return (acc += `sumIf(c, level >= ${index + 1}) AS _${index + 1}${isLast ? '\n' : ',\n'}`);
	}, '');
};

const getEventAlias = (events: EventType[]): string => {
	return `[${events.reduce<string>((acc, _, index) => {
		const isLast = index === events.length - 1;
		return (acc += `_${index + 1}${isLast ? '' : ', '}`);
	}, '')}]`;
};

const getStringEventTypes = (events: EventType[]): string => {
	return events.reduce<string>((acc, item, index) => {
		const isLast = index === events.length - 1;
		return (acc += `${item.value}${isLast ? '' : ', '}`);
	}, '');
};

const getFunnelGranularity = (funnelWindow: FunnelType): number => {
	switch (funnelWindow.granularity) {
		case FunnelGranularityTypes.Days:
			return Number(funnelWindow.value) * 24 * 60 * 60;
		case FunnelGranularityTypes.Hours:
			return Number(funnelWindow.value) * 60 * 60;
		case FunnelGranularityTypes.Minutes:
			return Number(funnelWindow.value) * 60;
		default:
			return Number(funnelWindow.value);
	}
};

const getT1 = (groupBy: FilterType[], eventsWithProps: string, whereStep1: string) => {
	let selectFields = `
select
	t1.user_id as epoch_user_id
	,t1.timestamp as event_timestamp
	,t1.event_type as event_type
    `;

	if (groupBy.length > 0) {
		selectFields += `,${groupBy
			.map(item =>
				item.type === FilterTypes.DateTime || item.type === FilterTypes.Date
					? `${item.granularity}(t1.${item.name}) as ${item.name}__${item.granularity}`
					: `t1.${item.name} as ${item.name}`,
			)
			.join('\n,')}\n`;
	}

	return `with t1 as (
        ${selectFields}
        from events_map as t1
		where ( ${eventsWithProps} ) 
		${whereStep1}
    `;
};

const getT2 = (
	groupBy: FilterType[],
	eventsOrder: string,
	funnelWindow: FunnelType,
	eventTypes: string,
) => {
	return eventsOrder === 'any'
		? `
	select
		event_type as step
		,uniqExact(epoch_user_id) as user_count
		${
			groupBy.length
				? ',' +
				  groupBy
						.map(item =>
							item.type === FilterTypes.DateTime || item.type === FilterTypes.Date
								? `${item.name}__${item.granularity}`
								: item.name,
						)
						.join('\n,')
				: ''
		}
	from 
		t1
	group by 1${
		groupBy.length
			? ', ' +
			  groupBy.map(item =>
					item.type === FilterTypes.DateTime || item.type === FilterTypes.Date
						? `${item.name}__${item.granularity}`
						: item.name,
			  )
			: ''
	}
	order by 2 desc`
		: `
	,t2 as (
	select
		epoch_user_id
		,windowFunnel(
			${getFunnelGranularity(funnelWindow)}
			${eventsOrder === 'strict_order' ? ",'" + eventsOrder + "'" : ''}
			)(event_timestamp, ${eventTypes}) AS level
		${
			groupBy.length
				? ',' +
				  groupBy.map(item =>
						item.type === FilterTypes.DateTime || item.type === FilterTypes.Date
							? `${item.name}__${item.granularity}`
							: item.name,
				  )
				: ''
		}
	from t1
	group by
 	epoch_user_id
	 ${
			groupBy.length
				? ', ' +
				  groupBy.map(item =>
						item.type === FilterTypes.DateTime || item.type === FilterTypes.Date
							? `${item.name}__${item.granularity}`
							: item.name,
				  )
				: ''
		}
)`;
};

const getT3 = (groupBy: FilterType[], eventsOrder: string) => {
	if (eventsOrder === 'any') {
		return '';
	}
	return `,
	t3 as (
	select
	  level
	  ,uniqExact(epoch_user_id) as c
	  ${
				groupBy.length
					? ',' +
					  groupBy
							.map(item =>
								item.type === FilterTypes.DateTime || item.type === FilterTypes.Date
									? `${item.name}__${item.granularity}`
									: item.name,
							)
							.join('\n,')
					: ''
			}
	from t2
	  where level != 0
	  group by 
	  level
	  ${
				groupBy.length
					? ',' +
					  groupBy
							.map(item =>
								item.type === FilterTypes.DateTime || item.type === FilterTypes.Date
									? `${item.name}__${item.granularity}`
									: item.name,
							)
							.join('\n,')
					: ''
			}
	  ),\n`;
};

const getT4 = (
	groupBy: FilterType[],
	eventsOrder: string,
	eventsByLevel: string,
	eventAlias: string,
	eventTypesString: string,
) => {
	if (eventsOrder === 'any') {
		return '';
	}
	return `t4 as (
		SELECT 
		${eventsByLevel}
		${
			groupBy.length
				? ',' +
				  groupBy
						.map(item =>
							item.type === FilterTypes.DateTime || item.type === FilterTypes.Date
								? `${item.name}__${item.granularity}`
								: item.name,
						)
						.join('\n,')
				: ''
		}
		FROM t3
		${
			groupBy.length
				? 'GROUP BY ' +
				  groupBy
						.map(item =>
							item.type === FilterTypes.DateTime || item.type === FilterTypes.Date
								? `${item.name}__${item.granularity}`
								: item.name,
						)
						.join('\n,')
				: ''
		}
	)
	SELECT 
		step
		,cnt as user_count
		${
			groupBy.length
				? ',' +
				  groupBy
						.map(item =>
							item.type === FilterTypes.DateTime || item.type === FilterTypes.Date
								? `${item.name}__${item.granularity}`
								: item.name,
						)
						.join('\n,')
				: ''
		}
	FROM t4
	ARRAY JOIN
		${eventAlias} AS cnt,
		splitByString(', ', '${eventTypesString}') AS step
			`;
};
